import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
export function AllButtons({
  isButtonClicked,
  paynow,
  paid,
  notarizeNowHandler,
  scheduleHandler,
  draftHandler,
  noOfSigner,
  auth,
  user,
  prepayhandler,
  resumeData,
  notarizeNowitHandler,
  selectedNotary,
  setSelectedNotary,
  jobPostHandler,
  confirmjobPostHandler,
  showReNotary,
  iscontinue,
  isalreadypaid,
  singerInfo,
  isBiometric
}) {
  const {
    paynowLoading,
    draftLoading,
    scheduleLoading,
    KBASettings
  } = useSelector(state => state.notarization);
  // const { allJournals, listLoading, customizeJournals } = notarization;
  return (
    <div className={`card card-custom`}>
      <div className="card-body pt-5 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <tbody>
                <tr>
                  <td className="text-center">
                    {user && !paid && (
                      <Button
                        className="mr-4 ml-3"
                        style={{
                          backgroundColor: "#1840d2",
                          borderColor: "#1840d2",
                          minWidth: "150px"
                        }}
                        onClick={paynow}
                        disabled={paid || isButtonClicked ? "disabled" : ""}
                      >
                        <span>Start Here</span>
                      </Button>
                    )}
                    {(user || (!user && iscontinue)) && (
                      <Button
                        className="mr-4 ml-3"
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "#1840d2",
                          color: "#1840d2",
                          minWidth: "150px"
                        }}
                        disabled={isButtonClicked ? "disabled" : ""}
                        onClick={() => draftHandler("draft")}
                      >
                        Save It
                        {draftLoading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </Button>
                    )}

                    {!user && !iscontinue && (
                      <Button
                        className="mr-4 ml-3"
                        style={{
                          backgroundColor: "transparent",
                          borderColor: "#1840d2",
                          color: "#1840d2",
                          minWidth: "150px"
                        }}
                        disabled={isButtonClicked ? "disabled" : ""}
                        onClick={() => draftHandler("draft", null, true)}
                      >
                        Continue
                        {draftLoading && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </Button>
                    )}
                    {
                      paid &&
                      user &&
                      selectedNotary &&
                      selectedNotary.primary &&
                      !isBiometric() && (
                        <Button
                          className="mr-4 ml-3"
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "#1840d2",
                            color: "#1840d2",
                            minWidth: "150px"
                          }}
                          onClick={scheduleHandler}
                          disabled={!paid || isButtonClicked ? "disabled" : ""}
                        >
                          Schedule It
                          {scheduleLoading && (
                            <span className="ml-3 spinner spinner-white"></span>
                          )}
                        </Button>
                      )
                    }

                    {
                      paid &&
                      user &&
                      selectedNotary &&
                        selectedNotary.primary &&
                        !isBiometric() && (
                        <Button
                          className="mr-4 ml-3"
                          style={{
                            backgroundColor: "#1840d2",
                            borderColor: "#1840d2",
                            minWidth: "150px"
                          }}
                          onClick={notarizeNowHandler}
                          disabled={!paid || isButtonClicked ? "disabled" : ""}
                        >
                          {user ? "Notarize It" : "Send To Client"}
                        </Button>
                      )}

                    {
                      paid &&
                      user &&
                      ((selectedNotary.primary && isBiometric()) ||
                        (selectedNotary &&
                          !selectedNotary.primary &&
                          !showReNotary &&
                          KBASettings &&
                            KBASettings.data &&
                            KBASettings.data &&
                            !KBASettings.data.settings.bypassAllowed)) && (
                        <Button
                          className="mr-4 ml-3"
                          style={{
                            backgroundColor: "#1840d2",
                            borderColor: "#1840d2",
                            minWidth: "150px"
                          }}
                          onClick={confirmjobPostHandler}
                          disabled={!paid || isButtonClicked ? "disabled" : ""}
                        >
                          Connect with Notary
                        </Button>
                      )}

                    {!user && iscontinue && (
                      <Button
                        className="mr-4 ml-3"
                        style={{
                          backgroundColor: "#1840d2",
                          borderColor: "#1840d2",
                          minWidth: "150px"
                        }}
                        disabled={isButtonClicked ? "disabled" : ""}
                        onClick={notarizeNowHandler}
                      >
                        {user ? "Notarize It" : "Send to Client for Payment"}
                      </Button>
                    )}

                    {!user && !isalreadypaid && iscontinue ? (
                      <>
                        {" "}
                        <Button
                          className="mr-4 ml-3"
                          style={{
                            backgroundColor: "#1840d2",
                            borderColor: "#1840d2",
                            minWidth: "150px"
                          }}
                          disabled={isButtonClicked ? "disabled" : ""}
                          onClick={paynow}
                        >
                          Pre Pay
                        </Button>
                      </>
                    ) : (
                      ""
                    )}

                    {!user && isalreadypaid ? (
                      <>
                        {" "}
                        <Button
                          className="mr-4 ml-3"
                          style={{
                            backgroundColor: "#1840d2",
                            borderColor: "#1840d2",
                            minWidth: "150px"
                          }}
                          disabled={isButtonClicked ? "disabled" : ""}
                          onClick={scheduleHandler}
                        >
                          Schedule it
                        </Button>{" "}
                        <Button
                          className="mr-4 ml-3"
                          style={{
                            backgroundColor: "#1840d2",
                            borderColor: "#1840d2",
                            minWidth: "150px"
                          }}
                          disabled={isButtonClicked ? "disabled" : ""}
                          onClick={notarizeNowitHandler}
                        >
                          Notarize it
                        </Button>
                      </>
                    ) : (
                      ""
                    )}

                    {/* <Button className="mr-4 ml-3" style={{ backgroundColor: '#1840d2', borderColor: '#1840d2', minWidth: '150px' }} onClick={notarizeNowHandler} >{user ? 'Send Email & Notarize Now' : 'Send To Client'}</Button> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
